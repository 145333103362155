@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {
  font-family: "Roboto", sans-serif;
  background-color: white;
}
/* 
html, body {
  cursor: url('./assets/glowing-cursor-2.png'), auto;
} */

#main::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
.montserrat{
  font-family: "Montserrat", serif;
}

.opensans{
  font-family: "Open Sans", sans-serif;
}

h1,h2,h3,h4,h5,h6{
  font-family: "Open Sans", sans-serif;
  font-weight: 600; 
}
p{
  font-family: "Montserrat", serif;
  font-weight: 500;
}
.font span{
  font-family: "Montserrat", serif;
}w

/* HTML: <div class="loader"></div> */
.loaderAdd {
  width: 20px;
  padding: 4px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #846cf9;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

.decrease-scrollbar-width {
  /* Firefox */
  scrollbar-width: thin;
  /* border-radius: 2px; */
}

.decrease-scrollbar-width::-webkit-scrollbar {
  width: 4px;
}

@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
